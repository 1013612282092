import React from "react";
import Highlighter from "react-highlight-words";

import { CopyToClipboard } from "react-copy-to-clipboard";
import VisibilitySensor from "react-visibility-sensor";

import isReachable from "is-reachable";

import * as ArtyApi from "../ArtyApi.js";
import * as ArtySession from "../ArtySession.js";

import * as ArtyNotif from "./ArtyNotif";

export default class extends React.Component {
    constructor(props) {
        super(props);

        const shouldExpand = props.expanded || props.item.group === "SOCIAL" || false;
        let highlightPhrase = props.highlightPhrase;

        let trackVisibleInViewport = true;

        if (props.item.document_seen) {
            trackVisibleInViewport = false;
        }

        if (highlightPhrase !== undefined) {
            if (/^".*"$/.test(highlightPhrase[0])) {
                for (let i = 0; i < highlightPhrase.length; i++) {
                    highlightPhrase[i] = highlightPhrase[i].replace(/"/g, "");
                }
            }
        }

        this.state = {
            type: props.type,
            expanded: shouldExpand,
            item: props.item,
            shareActive: false,
            highlightPhrase: highlightPhrase,
            trackVisibleInViewport: trackVisibleInViewport,
            nrOfLetters: 200,
            url: props.item.url,
        };
    }

    async updateUrl() {
        const resolvedUrl = await getUrlOrBackup(
            this.props.item.url,
            this.props.item.backupUrl
        );
        this.setState({ url: resolvedUrl });
    }

    componentDidMount() {
        const root = this.refs.root;
        this.updateUrl();

        if (this.state.item.group === "social") {
            expandExternalsInElement(root.querySelector("p"), true);
            expandExternalsInElement(root.querySelector("h2"), false);
        }
    }

    render() {
        const props = this.state.item;
        if (props.isNewItemsSeperator) {
            return (
                <div className="no-new-items-seperator">
                    <i className="fa fa-check"></i>
                    Du har sett alla nya artiklar
                </div>

                /* Top Link List Section */
            );
        }

        if (props.type === "SOCIAL") {
            return this.renderTwitter(props);
        }

        if (props.type === "PROTOCOL") {
            return this.renderProtocol(props);
        }
        return (
            <VisibilitySensor
                onChange={this.handleVisibleInViewport}
                intervalDelay={1500}
                scrollCheck={true}
                scrollThrottle={1500}
                offset={{ bottom: -20 }}
                delayedCall={true}
                partialVisibility={false}
                active={this.state.trackVisibleInViewport}
            >
                <article className="arty-article" ref="root">
                    <div className="content">
                        <Poster type={props.type} />
                        <Title label={props.title} expanded={this.state.expanded} />
                        <Meta time={props.publishedAt} source={translateSource(props)} />
                        <DebugInfo item={props} />
                        <div className="article-actions">
                            <ThumbButton
                                icon="fa-thumbs-up"
                                handler={this.handleUpvote}
                                visible={this.displayLikeButton()}
                                list={props.document_upvote}
                            />
                            <ThumbButton
                                icon="fa-thumbs-down"
                                extraClass="neg"
                                handler={this.handleDownvote}
                                visible={this.displayLikeButton()}
                                list={props.document_downvote}
                            />
                            <ThumbButton
                                icon="fa-star"
                                extraClass="star"
                                handler={this.handlePromote}
                                list={props.document_promoted}
                            />
                            <ShareButton id={props.id} active={this.state.shareActive} />
                            <ThumbButton
                                icon="fa-eye"
                                handler={this.handleSeenBy}
                                list={props.document_seen}
                                needSuperUser={true}
                            />
                            <ShareMenu
                                title={props.title}
                                url={props.url}
                                clipboardHandler={this.handleClipboard}
                                externSocialVisible={this.displayExternSocialButton}
                                emailHandler={this.handleEmail}
                                socialHandler={this.handleSocial}
                            />
                        </div>
                        <Description
                            text={props.text}
                            url={props.url}
                            expanded={this.state.expanded}
                        />
                        <a
                            onClick={this.handeOnClick}
                            href={props.url}
                            className="article-link"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            Till artikel
                        </a>
                    </div>

                    {/* In List Protocol Excerpt */}
                </article>

                {/* In List Locked Package Articles */}
            </VisibilitySensor>
        );
    }

    renderTwitter(props) {
        const name = props.sourceMeta.user.name;
        const url =
            "https://twitter.com/" +
            props.sourceMeta.user.screenName +
            "/" +
            "status/" +
            props.externalId;
        const screenName = props.sourceMeta.user.screenName;

        const textList = props.text.split(" ");

        const text = (
            <p>
                {textList.map((word) => {
                    if (word.startsWith("@")) {
                        return (
                            <a
                                onClick={this.handeOnClick}
                                target="_blank"
                                rel="noopener noreferrer"
                                href={"https://twitter.com/" + word.split("@")[1]}
                            >
                                {word}
                            </a>
                        );
                    }

                    if (word.startsWith("#")) {
                        const tag = word.split("#")[1];
                        return (
                            <a
                                onClick={this.handeOnClick}
                                target="_blank"
                                rel="noopener noreferrer"
                                href={`https://twitter.com/search?q=%23${tag}l&src=typeahead_click&f=top`}
                            >
                                {word}
                            </a>
                        );
                    }
                    if (word.startsWith("http")) {
                        return (
                            <a
                                onClick={this.handeOnClick}
                                target="_blank"
                                rel="noopener noreferrer"
                                href={word}
                            >
                                {word + " "}
                            </a>
                        );
                    } else return word + " ";
                })}
            </p>
        );

        return (
            <VisibilitySensor
                onChange={this.handleVisibleInViewport}
                intervalDelay={1500}
                scrollCheck={true}
                scrollThrottle={1500}
                offset={{ bottom: -20 }}
                delayedCall={true}
                partialVisibility={false}
                active={this.state.trackVisibleInViewport}
            >
                <article
                    className="arty-twitter-post"
                    ref="root"
                    data-date={getFormattedDate(props.publishedAt)}
                >
                    <div className="twitter-header">
                        <div className="inner">
                            <img src={props.sourceMeta.user.profileImageUrl} alt="" />
                            <h3>
                                {name}{" "}
                                <a
                                    onClick={this.handeOnClick}
                                    href={url}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    @{screenName}
                                </a>
                            </h3>
                        </div>
                        <p>{text}</p>
                    </div>
                </article>
            </VisibilitySensor>
        );
    }

    renderProtocol(props) {
        return (
            <VisibilitySensor
                key={this.state.item.toString}
                onChange={this.handleVisibleInViewport}
                intervalDelay={1500}
                scrollCheck={true}
                scrollThrottle={1500}
                offset={{ bottom: -20 }}
                delayedCall={true}
                partialVisibility={false}
                active={this.state.trackVisibleInViewport}
            >
                <article className="arty-article" ref="root">
                    <div className="content">
                        <Poster type={props.type} />
                        <ProtocolHeaders
                            title={props.sourceMeta.name}
                            time={props.publishedAt}
                        />
                        <a
                            onClick={this.handeOnClick}
                            href={`${process.env.REACT_APP_PDF_URL}?url=${this.state.url}`}
                            className="article-link"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            Till protokoll
                        </a>
                        <div onClick={this.handleShowMore} className="read-more">
                            <i
                                className={
                                    this.state.hidden
                                        ? "fa fa-angle-right show-more-button-hidden"
                                        : "fa fa-angle-down"
                                }
                                style={{ padding: 6 + "px", fontWeight: "bold" }}
                            ></i>
                            {this.state.hidden ? "Visa mer" : "Göm"}
                        </div>
                        <div
                            className={
                                this.state.hidden ? "hide-excerpts" : "show-excerpts"
                            }
                        >
                            <Snippets
                                term={this.state.highlightPhrase}
                                snippets={props.extraData.snippets}
                                url={this.state.url}
                            />
                        </div>
                    </div>
                </article>
            </VisibilitySensor>
        );
    }

    handleUpvote = async (event) => {
        event.preventDefault();

        if (this.props.revalidate) this.props.revalidate();

        try {
            ArtyApi.articleUpvote(this.state.item.id);
        } catch (error) {
            console.error(error.stack);
            return;
        }

        const { item } = this.state;

        if (item.document_upvote) {
            item.document_upvote = false;
        } else {
            item.document_upvote = true;
            item.document_downvote = false;
        }

        this.setState({ item });
    };

    handeOnClick = async (event) => {
        try {
            this.touchInteraction("CLICKED_ON");
        } catch (error) {
            return;
        }
    };

    handleDownvote = async (event) => {
        event.preventDefault();

        try {
            await ArtyApi.articleDownvote(this.state.item.id);
        } catch (error) {
            console.error(error.stack);
            return;
        }

        const { item } = this.state;

        if (item.document_downvote) {
            item.document_downvote = false;
        } else {
            item.document_upvote = false;
            item.document_downvote = true;
        }

        this.setState({ item });
    };

    handlePromote = async (event) => {
        event.preventDefault();

        try {
            await ArtyApi.articlePromote(this.state.item.id);
            this.props.revalidate();
        } catch (error) {
            console.error(error.stack);
            return;
        }

        const { item } = this.state;

        if (item.document_promoted) {
            item.document_promoted = false;
        } else {
            item.document_promoted = true;
        }

        this.setState({ item });
    };

    handleClipboard = (event) => {
        ArtyNotif.notif("Länken kopierad");
        this.closeShareMenu(event.target);
        this.touchInteraction("document_shared_copied");
    };

    handleEmail = (event) => {
        this.closeShareMenu(event.target);
        this.touchInteraction("document_shared_email");
    };

    handleSocial = (event) => {
        this.closeShareMenu(event.target);
        this.touchInteraction("document_shared_social_media");
    };

    handleExpand = () => {
        this.touchInteraction("document_opened");
        this.setState({
            expanded: true,
        });
    };

    handleVisibleInViewport = (visible) => {
        if (visible) {
            this.touchInteraction("VIEWED");
            this.setState({
                trackVisibleInViewport: false,
            });
        }
    };

    handleSeenBy = () => {
        if (this.state.item.document_seen) {
            return;
        }

        this.touchInteraction("VIEWED");
        this.setState({
            document_seen: true,
            trackVisibleInViewport: false,
        });
    };

    handleShowMore = async () => {
        this.setState({
            hidden: !this.state.hidden,
        });
    };

    // Private

    closeShareMenu(target) {
        const article = target.closest(".arty-article");
        const shareButton = article.querySelector(".share-button");
        shareButton.checked = false;
    }

    touchInteraction(type) {
        ArtyApi.articleTrackInteraction(this.state.item.id, type)
            .then(() => {})
            .catch((error) => {
                console.error(error.stack);
            });
    }

    displayLikeButton() {
        if (!this.state.item) {
            return false;
        }

        return true;
    }

    displayExternSocialButton = () => {
        if (!this.state.item) {
            return false;
        }

        if (ArtySession.group() === "testhealth" && this.state.item.group === "social") {
            return false;
        }

        return true;
    };

    displayLinkedInButton = () => {
        return true;
    };
}

// Internals
function Poster(props) {
    if (props.type === "DECISION") {
        return (
            <div className="poster decision">
                <span className="article-type secondary-font">
                    <i className="fa fa-archive" aria-hidden="true"></i> Beslut
                </span>
            </div>
        );
    } else if (props.type === "PROTOCOL") {
        return (
            <div className="poster protocol">
                <span className="article-type secondary-font">
                    <i className="fa fa-file-pdf-o" aria-hidden="true"></i> Protokoll
                </span>
            </div>
        );
    } else {
        return (
            <div className="poster">
                <span className="article-type secondary-font">
                    <i className="fa fa-id-card-o" aria-hidden="true"></i> Media
                </span>
            </div>
        );
    }
}

function Title(props) {
    const expanded = props.expanded ? "expanded" : "";

    return <h2 className={expanded}>{props.label}</h2>;
}

function Meta(props) {
    const date = new Date(props.time);
    const year = date.toLocaleString("default", { year: "numeric" });
    const month = date.toLocaleString("default", { month: "short" }).toLowerCase();
    const day = date.toLocaleString("default", { day: "2-digit" });
    const datestr = `${year}-${month}-${day}`;
    const source = props.source;

    return (
        <ul className="article-meta">
            <li>{datestr}</li>
            <li>{source}</li>
        </ul>
    );
}

function DebugInfo(props) {
    const item = props.item;

    if (ArtySession.isSuperUser() === false) {
        return null;
    }

    return (
        <ul className="article-meta">
            <li>Id: {item.id}</li>
            <li>Algo: {item.algo}</li>
            {item.documentScore && (
                <li>
                    Score: {(Number.parseInt(item.documentScore) / 1_000_000).toFixed(3)}
                </li>
            )}
        </ul>
    );
}

function ProtocolHeaders(props) {
    if (props.title.length > 0) {
        const MAX_LENGTH = 60;
        const title =
            props.title.length > MAX_LENGTH
                ? props.title.substr(0, MAX_LENGTH - 5) + "..."
                : props.title;
        return (
            <div>
                <span className="protocol-headers">
                    Källa: <span>{props.title}</span>
                </span>
                <span className="protocol-title">
                    <span>{title}</span>
                </span>
                <span className="protocol-date">
                    <span style={{ fontWeight: "bold" }}>Publicering:</span>{" "}
                    {getFormattedDate(props.time)}
                </span>
            </div>
        );
    }

    return (
        <div>
            <span className="protocol-headers">
                Källa: <span>{props.source}</span>
            </span>
            <span className="protocol-date">
                <span style={{ fontWeight: "bold" }}>Publicering:</span>{" "}
                {getFormattedDate(props.time)}
            </span>
        </div>
    );
}

function Description(props) {
    const nrOfLetters = 200;
    const text = props.expanded ? props.text : props.text.substr(0, nrOfLetters) + "...";
    return <p className={props.expanded ? "expanded" : ""}>{text}</p>;
}

function Snippets(props) {
    const goToProtocol = async (p) => {
        const link = p.url.split("#")[0];
        const searchTerm = props.term[0];
        const page = p.page;

        let url =
            searchTerm !== "" && searchTerm !== undefined
                ? `${process.env.REACT_APP_PDF_URL}?search=${searchTerm}&url=${link}`
                : `${process.env.REACT_APP_PDF_URL}?url=${link}&page=${page}`;

        window.open(url, "_blank").focus();
    };
    const sinppets = props.snippets.map((e, i) => {
        const snippetText = e.text !== undefined ? e.text : e;
        const toProt = async (div) => {
            goToProtocol({
                url: div.currentTarget.getAttribute("data-value-url"),
                page: div.currentTarget.getAttribute("data-value-page"),
                snippet: cleanSnippet(snippetText),
            });
        };

        return i < 3 ? (
            <div
                key={i}
                className="excerpt-container"
                onClick={toProt}
                data-value-url={props.url}
                data-value-page={e.page}
            >
                <span>
                    <span className="excerpt-other">Utdrag:</span>
                    <Highlighter
                        highlightClassName="highlighttext"
                        className="excerpt-text"
                        searchWords={props.term ?? [""]}
                        textToHighlight={
                            (e.page !== undefined ? ` [p.${e.page}]  ` : "") +
                            cleanSnippet(e.text !== undefined ? e.text : e)
                        }
                    ></Highlighter>
                </span>
            </div>
        ) : null;
    });

    return sinppets;
}

function cleanSnippet(snippet) {
    return snippet.replaceAll("<em>", "").replaceAll("</em>", "");
}

function ShareMenu(props) {
    return (
        <div className="share-menu">
            <div className="button-group">
                <CopyToClipboard text={props.url}>
                    <button onClick={props.clipboardHandler}>
                        <i className="fa fa-clipboard" />
                    </button>
                </CopyToClipboard>
                <ShareTwitterButton
                    title={props.title}
                    url={props.url}
                    handler={props.socialHandler}
                />
                <ShareFacebookButton
                    title={props.title}
                    visible={props.externSocialVisible()}
                    url={props.url}
                    handler={props.socialHandler}
                />
                <ShareLinkedInButton
                    title={props.title}
                    visible={props.externSocialVisible()}
                    url={props.url}
                    handler={props.socialHandler}
                />
                <ShareEmailButton
                    title={props.title}
                    url={props.url}
                    handler={props.emailHandler}
                />
            </div>
        </div>
    );
}

function ShareEmailButton(props) {
    const email = "exempel@exempel.com";
    const subject = props.title;
    const body = `Kolla in denna artikeln: ${props.url}`;
    const href = `mailto:${email}?subject=${subject}&body=${body}`;

    return (
        <a href={href} onClick={props.handler}>
            <i className="fa fa-envelope-o" />
        </a>
    );
}

function ShareTwitterButton(props) {
    const { title, url } = props;
    const href = `https://twitter.com/intent/tweet?url=${url}&text=${title}`;

    return (
        <a href={href} onClick={props.handler} rel="noopener noreferrer" target="_blank">
            <i className="fa fa-twitter" />
        </a>
    );
}

function ShareFacebookButton(props) {
    if (props.visible === false) {
        return null;
    }

    const { title, url } = props;
    const href = `https://www.facebook.com/sharer/sharer.php?u=${url}&t=${title}`;

    return (
        <a href={href} onClick={props.handler} rel="noopener noreferrer" target="_blank">
            <i className="fa fa-facebook-official" />
        </a>
    );
}

function ShareLinkedInButton(props) {
    if (props.visible === false) {
        return null;
    }

    const { url } = props;
    const href = `https://www.linkedin.com/sharing/share-offsite/?url=${url}`;

    return (
        <a href={href} onClick={props.handler} rel="noopener noreferrer" target="_blank">
            <i className="fa fa-linkedin-square" />
        </a>
    );
}

function ThumbButton(props) {
    if (props.visible === false) {
        return null;
    }

    const containsUser = props.list;
    const cls = props.extraClass ? ` ${props.extraClass}` : "";
    const linkClass = `thumbs-button ${containsUser ? `active${cls}` : ""}`;
    const iconClass = `fa ${props.icon}`;

    if (props.needSuperUser && ArtySession.isSuperUser() === false) {
        return null;
    }

    return (
        <button className={linkClass} onClick={props.handler}>
            <i className={iconClass} />
        </button>
    );
}

function ShareButton(props) {
    const id = `share-button-${props.id}"`;

    return (
        <React.Fragment>
            <input
                id={id}
                type="checkbox"
                className="share-button"
                defaultChecked={props.active}
            />
            <label htmlFor={id}>
                <i className="fa fa-share-alt" />
            </label>
        </React.Fragment>
    );
}

function expandExternalsInElement(elem, includeTextLinks = false) {
    if (!elem) {
        return;
    }

    let text = elem.innerText;

    if (includeTextLinks) {
        text = expandTextLinks(text);
    }
    text = expandAtHandlers(text);
    text = expandHashTags(text);
    elem.innerHTML = text;
}

function expandTextLinks(text) {
    return (text || "").replace(
        /([^\S]|^)(((https?:\/\/)|(www\.))(\S+))/gi,
        (match, space, url) => {
            let href = url;

            if (!href.match("^https?://")) {
                href = `http://${href}`;
            }

            const defaults = `target="_blank" rel="noopener noreferrer"`;

            return `${space}<a href="${href}" ${defaults}>${href}</a>`;
        }
    );
}

function expandAtHandlers(text) {
    return (text || "").replace(
        /([^\S]|^)@([A-Za-z0-9_åÅäÄöÖ]+)/gi,
        (match, space, handle) => {
            const href = `https://twitter.com/${handle}`;
            const defaults = `target="_blank" rel="noopener noreferrer"`;

            return `${space}<a href="${href}" ${defaults}>@${handle}</a>`;
        }
    );
}

function expandHashTags(text) {
    return (text || "").replace(
        /([^\S]|^)#([A-Za-z0-9_åÅäÄöÖ]+)/gi,
        (match, space, tag) => {
            const href = `https://www.instagram.com/explore/tags/${tag}/`;
            const defaults = `target="_blank" rel="noopener noreferrer"`;

            return `${space}<a href="${href}" ${defaults}>#${tag}</a>`;
        }
    );
}

function translateSource(source) {
    if (source.sourceName) {
        return source.sourceName;
    }

    return source.source_title ?? source.source;
}

function getFormattedDate(time) {
    const date = new Date(time);
    const year = date.toLocaleString("default", { year: "numeric" });
    const month = date.toLocaleString("default", { month: "short" }).toLowerCase();
    const day = date.toLocaleString("default", { day: "2-digit" });

    return `${year}-${month}-${day}`;
}

async function getUrlOrBackup(url, backupUrl) {
    if (!backupUrl) {
        return url;
    }
    try {
        const response = await isReachable(url, { timeout: 20000 });
        return response ? url : backupUrl;
    } catch (error) {
        console.error("Error checking URL:", error);
        return backupUrl;
    }
}
