import "./ProtocolFilter.css";
import "../index.css";
import React, { useEffect, useState } from "react";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { MobileDatePicker } from "@mui/x-date-pickers";
import BannerComponent from '../components/BannerComponent';
import {
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    Grid,
    InputLabel,
    ListItemText,
    ListSubheader,
    MenuItem,
    OutlinedInput,
    Select,
    Stack,
    TextField,
} from "@mui/material";
import { useDebouncedCallback } from "use-debounce";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { staticSourceList } from "./protocol/searchConfig.js";
import dayjs from "dayjs";

export const ProtocolFilter = ({ filterUpdated }) => {
    const [startDate, setStartDate] = useState(dayjs().subtract(1, "month").valueOf());
    const [endDate, setEndDate] = useState(dayjs().valueOf());

    const [searchPhrase, setSearchPhrase] = useState("");

    const [sortOrder, setSortOrder] = useState("STRING_MATCH");
    const handleSortChange = (event) => {
        setSortOrder(event.target.value);
    };

    const [selectedSources, setSelectedSources] = React.useState(["Alla"]);

    const isEntireGroupSelected = (group) => {
        const selectedInGroup = selectedSources.filter((s) => group.value.includes(s));
        return selectedInGroup.length === group.value.length;
    };

    const isPartOfGroupSelected = (group) => {
        const selectedInGroup = selectedSources.filter((s) => group.value.includes(s));
        return selectedInGroup.length > 0 && selectedInGroup.length < group.value.length;
    };

    useEffect(() => {
        if (selectedSources.length === 1 && selectedSources[0] === "Alla") {
            setOpenedSourceGroups([]);
        }
        if (selectedSources.length > 1 && selectedSources.includes("Alla")) {
            setSelectedSources(selectedSources.filter((s) => s !== "Alla"));
        }
    }, [selectedSources]);
    const handleSourceChange = (event) => {
        let {
            target: { value },
        } = event;

        // If "Alla" is selected, unselect all other sources
        if (["Alla"].includes(value[value.length - 1]) || value.length === 0) {
            setSelectedSources(["Alla"]);
            setOpenedSourceGroups(["Alla"]);
            return;
        }

        // Handle "Select all" for a
        if (
            ["regering-all", "riksdagen-all", "myndigheter-all", "regioner-all"].includes(
                value[value.length - 1]
            )
        ) {
            let targetSourceGroup;

            switch (value[value.length - 1]) {
                case "regering-all":
                    targetSourceGroup = staticSourceList[0];
                    break;
                case "riksdagen-all":
                    targetSourceGroup = staticSourceList[1];
                    break;
                case "myndigheter-all":
                    targetSourceGroup = staticSourceList[2];
                    break;
                case "regioner-all":
                    targetSourceGroup = staticSourceList[3];
                    break;
                default:
                    break;
            }

            // If all in this group are already selected, unselect all in the group
            // but keep any selected options from other groups
            if (isEntireGroupSelected(targetSourceGroup)) {
                setSelectedSources(
                    selectedSources.filter((s) => !targetSourceGroup.value.includes(s))
                );
            }
            // Else select all in this group, keeping any selected options from other groups
            else {
                setSelectedSources([
                    ...new Set([...targetSourceGroup.value, ...selectedSources]),
                ]);
            }

            return;
        }

        setSelectedSources(typeof value === "string" ? value.split(",") : value);
    };

    const [openedSourceGroups, setOpenedSourceGroups] = React.useState([]);

    const toggleSourceGroup = (sourceGroup) => {
        setOpenedSourceGroups(
            openedSourceGroups.includes(sourceGroup)
                ? openedSourceGroups.filter((o) => o !== sourceGroup)
                : [...openedSourceGroups, sourceGroup]
        );
    };

    const [filterValue, setFilterValue] = React.useState({});

    const debounce = useDebouncedCallback(async () => {
        const query = queryBuilder(
            startDate,
            endDate,
            searchPhrase,
            sortOrder,
            selectedSources
        );

        const updateList = async (filterUpdated) => {
            setFilterValue(query);

            filterUpdated(query, true);
        };
        updateList(filterUpdated);
    }, 500);

    useEffect(() => {
        debounce();
    }, [
        filterUpdated,
        startDate,
        endDate,
        searchPhrase,
        sortOrder,
        selectedSources,
        debounce,
    ]);

    const SourceSelector = () => {
        const sources = staticSourceList;

        const ITEM_HEIGHT = 48;
        const ITEM_PADDING_TOP = 8;
        const MenuProps = {
            autoFocus: false,
            PaperProps: {
                style: {
                    maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                    width: 250,
                },
            },
        };

        return (
            <FormControl className="source-filter">
                <InputLabel id="multiple-checkbox-label">Källor</InputLabel>
                <Select
                    multiple
                    value={selectedSources}
                    onChange={(event) => {
                        handleSourceChange(event);
                    }}
                    input={<OutlinedInput label="Källor" />}
                    renderValue={(selected) => selected.join(", ")}
                    MenuProps={MenuProps}
                >
                    <MenuItem value="Alla">
                        <Checkbox checked={selectedSources[0] === "Alla"} />
                        <ListItemText primary="Alla" />
                    </MenuItem>

                    <ListSubheader
                        className="source-group__heading"
                        onClick={() => toggleSourceGroup("regering")}
                    >
                        Regering
                        {openedSourceGroups.includes("regering") ? (
                            <ArrowDropDownIcon />
                        ) : (
                            <ArrowRightIcon />
                        )}
                    </ListSubheader>
                    <MenuItem
                        sx={{
                            display: openedSourceGroups.includes("regering")
                                ? "flex"
                                : "none",
                        }}
                        value="regering-all"
                    >
                        <Checkbox
                            checked={isEntireGroupSelected(sources[0])}
                            indeterminate={isPartOfGroupSelected(sources[0])}
                        />
                        <ListItemText primary="Välj alla" />
                    </MenuItem>
                    {sources[0].value.map((name, i) => (
                        <MenuItem
                            sx={{
                                display: openedSourceGroups.includes("regering")
                                    ? "flex"
                                    : "none",
                            }}
                            key={i}
                            value={name}
                        >
                            <Checkbox checked={selectedSources.indexOf(name) > -1} />
                            <ListItemText primary={name} />
                        </MenuItem>
                    ))}

                    <ListSubheader
                        className="source-group__heading"
                        onClick={() => toggleSourceGroup("riksdagen")}
                    >
                        Riksdagen
                        {openedSourceGroups.includes("riksdagen") ? (
                            <ArrowDropDownIcon />
                        ) : (
                            <ArrowRightIcon />
                        )}
                    </ListSubheader>
                    <MenuItem
                        sx={{
                            display: openedSourceGroups.includes("riksdagen")
                                ? "flex"
                                : "none",
                        }}
                        value="riksdagen-all"
                    >
                        <Checkbox
                            checked={isEntireGroupSelected(sources[1])}
                            indeterminate={isPartOfGroupSelected(sources[1])}
                        />
                        <ListItemText primary="Välj alla" />
                    </MenuItem>
                    {sources[1].value.map((name, i) => (
                        <MenuItem
                            sx={{
                                display: openedSourceGroups.includes("riksdagen")
                                    ? "flex"
                                    : "none",
                            }}
                            key={i}
                            value={name}
                        >
                            <Checkbox checked={selectedSources.indexOf(name) > -1} />
                            <ListItemText primary={name} />
                        </MenuItem>
                    ))}

                    <ListSubheader
                        className="source-group__heading"
                        onClick={() => toggleSourceGroup("myndigheter")}
                    >
                        Myndigheter
                        {openedSourceGroups.includes("myndigheter") ? (
                            <ArrowDropDownIcon />
                        ) : (
                            <ArrowRightIcon />
                        )}
                    </ListSubheader>
                    <MenuItem
                        sx={{
                            display: openedSourceGroups.includes("myndigheter")
                                ? "flex"
                                : "none",
                        }}
                        value="myndigheter-all"
                    >
                        <Checkbox
                            checked={isEntireGroupSelected(sources[2])}
                            indeterminate={isPartOfGroupSelected(sources[2])}
                        />
                        <ListItemText primary="Välj alla" />
                    </MenuItem>
                    {sources[2].value.map((name, i) => (
                        <MenuItem
                            sx={{
                                display: openedSourceGroups.includes("myndigheter")
                                    ? "flex"
                                    : "none",
                            }}
                            key={i}
                            value={name}
                        >
                            <Checkbox checked={selectedSources.indexOf(name) > -1} />
                            <ListItemText primary={name} />
                        </MenuItem>
                    ))}

                    <ListSubheader
                        className="source-group__heading"
                        onClick={() => toggleSourceGroup("regioner")}
                    >
                        Regioner
                        {openedSourceGroups.includes("regioner") ? (
                            <ArrowDropDownIcon />
                        ) : (
                            <ArrowRightIcon />
                        )}
                    </ListSubheader>
                    <MenuItem
                        sx={{
                            display: openedSourceGroups.includes("regioner")
                                ? "flex"
                                : "none",
                        }}
                        value="regioner-all"
                    >
                        <Checkbox
                            checked={isEntireGroupSelected(sources[3])}
                            indeterminate={isPartOfGroupSelected(sources[3])}
                        />
                        <ListItemText primary="Välj alla" />
                    </MenuItem>
                    {sources[3].value.map((name, i) => (
                        <MenuItem
                            sx={{
                                display: openedSourceGroups.includes("regioner")
                                    ? "flex"
                                    : "none",
                            }}
                            key={i}
                            value={name}
                        >
                            <Checkbox checked={selectedSources.indexOf(name) > -1} />
                            <ListItemText primary={name} />
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        );
    };

    const SortingSelector = () => {
        return (
            <FormControl className="sort-filter">
                <InputLabel>Sortering</InputLabel>
                <Select
                    label={"Sortering"}
                    value={sortOrder}
                    onChange={(event) => {
                        handleSortChange(event);
                    }}
                    inputProps={{
                        name: "sortOrder",

                        id: "sortOrder",
                    }}
                >
                    <MenuItem value={"RELEVANCY"}>Relevant för dig</MenuItem>
                    <MenuItem value={"PUBLISHED_TIME"}>Datum</MenuItem>
                    <MenuItem value={"STRING_MATCH"}>Relevans</MenuItem>
                </Select>
            </FormControl>
        );
    };

    const SearchField = () => {
        return (
            <TextField
                className="search-bar"
                id="outlined-search"
                type="search"
                label="Sök"
                variant="outlined"
                value={searchPhrase}
                onChange={(event) => {
                    setSearchPhrase(event.target.value);
                }}
                fullWidth
            />
        );
    };
    const [renderDesktop, setRenderDesktop] = useState(true);
    useEffect(() => {
        const handleResize = () => {
            setRenderDesktop(window.innerWidth >= 600);
        };
        handleResize();

        window.addEventListener("resize", handleResize);

        return () => window.removeEventListener("resize", handleResize);
    }, []);
    const DatePicker = () => {
        return renderDesktop ? (
            <LocalizationProvider adapterLocale="sv" dateAdapter={AdapterDayjs}>
                <DesktopDatePicker
                    className="date-picker"
                    label="Startdatum"
                    inputFormat="YYYY-MM-DD"
                    value={startDate}
                    onChange={(newValue) => {
                        setStartDate(newValue.valueOf());
                    }}
                    renderInput={(params) => <TextField {...params} />}
                />
                <DesktopDatePicker
                    className="date-picker"
                    label="Slutdatum"
                    inputFormat="YYYY-MM-DD"
                    value={endDate}
                    onChange={(newValue) => {
                        setEndDate(newValue.valueOf());
                    }}
                    renderInput={(params) => <TextField {...params} />}
                />
            </LocalizationProvider>
        ) : (
            <LocalizationProvider adapterLocale="sv" dateAdapter={AdapterDayjs}>
                <MobileDatePicker
                    className="date-picker"
                    label="Startdatum"
                    inputFormat="YY-MM-DD"
                    value={startDate}
                    onChange={(newValue) => {
                        setStartDate(newValue.valueOf());
                    }}
                    renderInput={(params) => <TextField {...params} />}
                />
                <MobileDatePicker
                    className="date-picker"
                    label="Slutdatum"
                    inputFormat="YY-MM-DD"
                    value={endDate}
                    onChange={(newValue) => {
                        setEndDate(newValue.valueOf());
                    }}
                    renderInput={(params) => <TextField {...params} />}
                />
            </LocalizationProvider>
        );
    };

    const SaveFilter = () => {
        const [open, setOpen] = React.useState(false);
        const [filterName, setFilterName] = React.useState("");
        const handleClickOpen = () => {
            setOpen(true);
        };
        const handleClose = () => {
            setOpen(false);
        };
        const handleSave = () => {
            let filters = JSON.parse(localStorage.getItem("AtyProtocolFilter"));
            if (filters === null) {
                filters = [];
            }
            filters.push({ name: filterName, value: filterValue });
            localStorage.setItem("AtyProtocolFilter", JSON.stringify(filters));
            setOpen(false);
        };
        return (
            <div>
                <Button
                    className="filter-saver"
                    variant="outlined"
                    onClick={handleClickOpen}
                >
                    Spara
                    <br />
                    sökning
                </Button>
                <Dialog open={open} onClose={handleClose}>
                    <DialogTitle>Spara sökning</DialogTitle>
                    <DialogContent>
                        <TextField
                            autoFocus
                            margin="dense"
                            id="filterName"
                            label="Sökning"
                            type="text"
                            fullWidth
                            value={filterName}
                            onChange={(event) => {
                                setFilterName(event.target.value);
                            }}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose}>Avbryt</Button>
                        <Button onClick={handleSave}>Spara</Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    };

    const setFilterValues = (filter) => {
        setStartDate(filter.query.date.from);
        setEndDate(filter.query.date.to);
        setSearchPhrase(filter.query.text);

        setSelectedSources(filter.filters[2].filterValues);
    };

    const LoadFilter = () => {
        const [open, setOpen] = React.useState(false);
        const [filterName, setFilterName] = React.useState("");
        const [filters, setFilters] = React.useState(["All"]);
        const handleClickOpen = () => {
            setOpen(true);
            let filters = JSON.parse(localStorage.getItem("AtyProtocolFilter"));
            if (filters === null) {
                filters = [];
            }
            setFilters(filters);
        };
        const handleClose = () => {
            setOpen(false);
        };
        const handleLoad = async () => {
            let filter = filters.filter((filter) => filter.name === filterName);
            if (filter.length > 0) {
                setFilterValue(filter[0].value);
                setFilterValues(filter[0].value);
            }
            setOpen(false);
        };

        const handleRemove = () => {
            let filters = JSON.parse(localStorage.getItem("AtyProtocolFilter")).filter(
                (filter) => filter.name !== filterName
            );
            localStorage.setItem("AtyProtocolFilter", JSON.stringify(filters));
            setFilters(filters);
            setOpen(false);
        };

        return (
            <div>
                <Button
                    className="filter-loader"
                    variant="outlined"
                    onClick={handleClickOpen}
                >
                    Sparade sökningar
                </Button>
                <Dialog open={open} onClose={handleClose}>
                    <DialogTitle>Mina sparade sökningar</DialogTitle>
                    <DialogContent>
                        <FormControl sx={{ m: 1, minWidth: 200 }}>
                            <InputLabel>Sökningar</InputLabel>
                            <Select
                                label="Sökningar"
                                value={filterName}
                                onChange={(event) => {
                                    setFilterName(event.target.value);
                                }}
                                inputProps={{
                                    name: "filterName",
                                    id: "filterName",
                                }}
                            >
                                {filters.map((filter) => (
                                    <MenuItem key={filter.name} value={filter.name}>
                                        {filter.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleLoad}>Sök</Button>
                        <Button onClick={handleClose}>Avbryt</Button>
                        <Button onClick={handleRemove}>Ta bort</Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    };

    return (
        <>
            <Grid className="protocol-filter">
                <div className="protocol-filter-inner">
                    <Stack
                        spacing={8}
                        className="protocol-filter-search-wrapper"
                        direction="row"
                    >
                        <Stack spacing={2} className="protocol-filter-search">
                            <Stack className="protocol-filter-upper" direction="row">
                                {DatePicker()}
                                {SourceSelector()}
                            </Stack>

                            <Stack className="protocol-filter-lower" direction="row">
                                {SearchField()}
                                {SortingSelector()}
                            </Stack>
                        </Stack>
                    </Stack>

                    <Stack spacing={2} className="protocol-filter-saver">
                        <SaveFilter />
                        <LoadFilter />
                    </Stack>
                </div>
            </Grid>
            <BannerComponent />
        </>
    );
    function queryBuilder(startDate, endDate, searchPhrase, sortOrder, sourceSelector) {
        const searchConfig = {
            query: {
                tags: sourceSelector.includes("Alla") ? [] : sourceSelector,
                type: "PROTOCOL",
                text: searchPhrase,
                date: {
                    to: endDate,
                    from: startDate,
                },
            },
            orderBy: {
                field: sortOrder,
                order: "DESC",
            },
        };

        if (sourceSelector === "ALL") {
            delete searchConfig["query"]["type"];
        }

        return searchConfig;
    }
};
